import dynamic from 'next/dynamic';
import React from 'react';

const ErrorPage = dynamic(() => import('@/features/ErrorPage'), { ssr: false });
const AdminAuditLogs = dynamic(
  () => import('@/features/AdminAuditLogs/AdminAuditLogs'),
  { ssr: false }
);
const AuditLogDetails = dynamic(
  () => import('@/features/AuditLogDetails/AuditLogDetails'),
  { ssr: false }
);
const UsersList = dynamic(() => import('@/features/UsersList/UsersList'), {
  ssr: false,
});
const UsersEditProfile = dynamic(
  () => import('@/features/UsersEditProfile/UsersEditProfile'),
  { ssr: false }
);
const SupportTicketsList = dynamic(
  () => import('@/features/SupportTicketsList/SupportTicketsList'),
  { ssr: false }
);
const ManageWhitelabeling = dynamic(
  () => import('@/features/ManageWhitelabeling/ManageWhitelabeling'),
  { ssr: false }
);
const SupportTicketsDetails = dynamic(() => import("@/features/SupportTicketsDetails/SupportTicketsDetails"),
  { ssr: false });

const SupportTicketsForm = dynamic(
  () => import("@/features/SupportTicketsForm/SupportTicketsForm"),
  { ssr: false });

const ManageMailTemplates = dynamic(
    () => import("@/features/ManageMailTemplates/ManageMailTemplates"),
    { ssr: false });

export {
  ErrorPage,
  AdminAuditLogs,
  AuditLogDetails,
  UsersList,
  ManageWhitelabeling,
  UsersEditProfile,
  SupportTicketsList,
  SupportTicketsDetails,
  SupportTicketsForm,
  ManageMailTemplates
};

export { default as Authenticate } from './Authenticate/Authenticate';
export { default as Logout } from './Logout/Logout';
export { default as ProtectedRoute } from './ProtectedRoute';
